import { useState } from "react";
import { CardProduct } from "../types/typeProduts";

import { URL_SERVER } from "../utils/Constants";
import { useDispatch } from "react-redux";
import { addProductBag } from "../redax/slices/productSlice";

import { ModuleQuote, ModuleBigFoto } from "./index";
import Clue from "./Clue";

export default function Card({
  foto,
  title,
  price,
  discription,
  _id,
  bag,
}: CardProduct) {
  const dispatch = useDispatch();
  const [bigFoto, isBigFoto] = useState(false);
  const [discriptionAll, isDiscriptionAll] = useState(false);
  //const [cardbag, isCardBag] = useState(false);

  const discriptionLength = discription.length > 50;

  const cutText = (text: string, num: number): string => {
    if (text.length < num) {
      return text;
    }
    return `${text.slice(0, num)}...`;
  };

  const changeBag = () => {
    dispatch(addProductBag({ title, price, _id, foto }));
    //isCardBag(!cardbag);
  };

  const onFotoKeydown = (evt: KeyboardEvent) => {
    if (evt.code === "Enter") {
      isBigFoto(true);
    }
  };

  const onDiscriptionKeydown = (evt: KeyboardEvent) => {
    if (evt.code === "Enter") {
      isDiscriptionAll(true);
    }
  };

  return (
    <div className="card">
      {foto ? (
        <Clue textClue="Фото в полном размере ">
          <img
            onFocus={(evt) => {
              evt.target.addEventListener("keydown", onFotoKeydown);
            }}
            onBlur={(evt) => {
              evt.target.removeEventListener("keydown", onFotoKeydown);
            }}
            tabIndex={foto ? 0 : -1}
            className={`card__foto-default ${foto ? `card__foto` : ""}`}
            onClick={() => foto && isBigFoto(true)}
            src={foto ? `${URL_SERVER}/${foto}` : "../images/images.png"}
            alt="фото товара"
          />
        </Clue>
      ) : (
        <img
          className={"card__foto-default"}
          src={"../images/images.png"}
          alt="перечеркнутое фото"
        />
      )}

      <h2 className="card__title">{title}</h2>
      <Clue active={discriptionLength} textClue="Полное описание товара">
        <h3
          onFocus={(evt) => {
            evt.target.addEventListener("keydown", onDiscriptionKeydown);
          }}
          onBlur={(evt) =>
            evt.target.removeEventListener("keydown", onDiscriptionKeydown)
          }
          tabIndex={discriptionLength ? 0 : -1}
          onClick={() => {
            discriptionLength && isDiscriptionAll(true);
          }}
          className={`card__discription ${
            discriptionLength ? "" : "card__discription_state_not-active"
          }`}
        >
          {cutText(discription, 50)}
        </h3>
      </Clue>
      <span className="card__price">{`${price} руб.`}</span>
      <Clue textClue="Добавление товара в сумку">
        <button
          onClick={() => changeBag()}
          className={`card__bag-plus ${
            bag ? "card__bag-plus_state_true" : "card__bag-plus_state_false"
          } `}
        ></button>
      </Clue>

      {bigFoto && (
        <ModuleBigFoto
          isShowBigAvatar={() => isBigFoto(false)}
          urlFoto={foto ? `${URL_SERVER}/${foto}` : "../images/images.png"}
        />
      )}
      {discriptionAll && (
        <ModuleQuote clickOverly={isDiscriptionAll} text={discription} />
      )}
    </div>
  );
}
