import { useEffect, useRef, useState } from "react";
import webSocket from "../utils/socket";

import {
  resetProductBag,
  selectProduct,
  setProductBag,
} from "../redax/slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { URL_SERVER } from "../utils/Constants";
import { BagProduct } from "../types/typeProduts";
import { useNavigate } from "react-router-dom";
import { isPopUpWindow } from "../redax/slices/popUpWindowSlice";

import {
  ModulConfirmation,
  ModulePreloader,
  ListOrders,
  ButtonsNavigation,
  Button,
  Form,
  InputPhone,
} from "../components/index";
import BlankPage from "../components/BlankPage";

export type objValueInput = {
  [key: string]: string;
};

export default function Bag() {
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productBag } = useSelector(selectProduct);
  const [textErr, isTextErr] = useState("");
  const [form, isForm] = useState(false);
  const [buttonShowForm, isButtonShowForm] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [preloader, isPreloader] = useState(false);
  const [idProduct, isIdProduct] = useState("");
  const [confirmation, isConfirmation] = useState(false);
  const [invalid, isInvalid] = useState<boolean>(false);
  const [inputValidity, isInputValidity] = useState(false);
  const [activeError, isActiveError] = useState(false);

  useEffect(() => {
    const bag = localStorage.getItem("bag");
    if (bag) {
      const bagPars = JSON.parse(bag);
      if (bagPars.length > 0) {
        isButtonShowForm(true);
        dispatch(setProductBag(bagPars));
      }
    }

    webSocket.on("ROOM:GET_NOT_READ_ORDER", setOrders);
  }, []);

  const setOrders = ({
    answer,
    status,
  }: {
    answer: string;
    status: boolean;
  }) => {
    if (status === true) {
      localStorage.removeItem("bag");
      dispatch(resetProductBag());
      formRef.current?.reset();
      setValuePhone("");
      navigate("/");
      setTimeout(() => dispatch(isPopUpWindow(true)), 1000);
      setTimeout(() => dispatch(isPopUpWindow(false)), 4000);
    } else {
      isTextErr(answer);
      setTimeout(() => isTextErr(""), 1300);
    }
    isPreloader(false);
  };

  const deleteElemInArray = (id: string, arr: BagProduct[]): BagProduct[] => {
    const arrPars = JSON.parse(JSON.stringify(arr));
    const indexElem = arrPars.findIndex((elem: BagProduct) => elem._id === id);
    arrPars.splice(indexElem, 1);
    return arrPars;
  };

  const collectValues = (
    evt: React.FormEvent<HTMLFormElement>
  ): objValueInput => {
    const elem = (evt.target as HTMLFormElement).elements;
    const objValue: objValueInput = {};
    for (let index = 0; index < elem.length; index++) {
      const elemCurrent = elem[index] as HTMLFormElement;
      const elemName = elemCurrent.name;
      if (elemName.length === 0) {
      } else {
        objValue[elemName] = elemCurrent.value;
      }
    }

    return objValue;
  };

  const validetionTextInput = (arrayString: string[]): boolean => {
    const regex = /[^\s]+/;
    let result = true;
    arrayString.forEach((value) => {
      if (regex.test(value) === false) {
        result = false;
      }
    });

    if (result) {
      return true;
    } else {
      return false;
    }
  };

  const errorValidText = (text: string): void => {
    isPreloader(false);
    isTextErr(text);
    setTimeout(() => {
      isActiveError(false);
      setTimeout(() => isTextErr(""), 1500);
    }, 1400);
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    isPreloader(true);
    evt.preventDefault();
    const { name, phone } = collectValues(evt);

    const validateInputLength = validetionTextInput([name, phone]);

    if (!validateInputLength) {
      isInvalid(true);
      errorValidText("все поля обязательны к заполнению");
      isActiveError(true);
      return;
    }

    if (!validPhone) {
      errorValidText("Введите телефон в формате +7 (777) 777 77 77");
      isActiveError(true);
      return;
    }
    webSocket.emit("ROOM:NEW_ORDERS", { name, phone, productBag });
  };

  const deleteOrder = (id: string): void => {
    const newArr = deleteElemInArray(id, productBag);
    dispatch(setProductBag(newArr));
    if (newArr.length <= 0) {
      isButtonShowForm(false);
      localStorage.removeItem("bag");
      isForm(false);
    } else {
      localStorage.setItem("bag", JSON.stringify(newArr));
    }
  };

  return (
    <div className="bag">
      <ButtonsNavigation page="/" text="На главную" />
      {productBag && productBag.length > 0 ? (
        <ul className="bag-list">
          {productBag.map((elem, i) => (
            <ListOrders key={elem._id} product={elem} url={URL_SERVER}>
              <button
                onClick={() => {
                  isIdProduct(elem._id);
                  isConfirmation(true);
                }}
                className="bag-list__product-delete"
              ></button>
            </ListOrders>
          ))}
        </ul>
      ) : (
        <BlankPage text={"Ваша сумка пустая (("} />
      )}

      {buttonShowForm && (
        <Button
          buttonClick={() => {
            isForm(true);
            isButtonShowForm(false);
          }}
          text="Заказать"
        />
      )}
      {form && (
        <Form
          formRef={formRef}
          handleSubmit={(evt) => {
            handleSubmit(evt);
          }}
          textButton="Создать заказ"
          textErr={textErr}
          activeError={activeError}
        >
          <label>Имя:</label>
          <input
            className={`input ${
              invalid
                ? inputValidity
                  ? "input_state_valid"
                  : "input_state_invalid-custom"
                : ""
            }`}
            onChange={(evt) =>
              isInputValidity(
                evt.target.checkValidity() &&
                  validetionTextInput([evt.target.value])
              )
            }
            maxLength={50}
            required
            name="name"
          ></input>

          <label>Номер телефона:</label>
          <InputPhone
            invalid={invalid}
            setValid={setValidPhone}
            valid={validPhone}
            valuePhone={valuePhone}
            setValuePhone={setValuePhone}
          />
        </Form>
      )}
      {preloader && <ModulePreloader text="Отправка..." />}
      {confirmation && (
        <ModulConfirmation
          text="Удалить?"
          confirm={() => {
            deleteOrder(idProduct);
          }}
          close={() => isConfirmation(false)}
        />
      )}
    </div>
  );
}
