type ErrorProps = {
  text: string | undefined;
  active: boolean;
};

export default function ErrorSpan({ text, active }: ErrorProps) {
  return (
    <span
      className={`errors ${active ? "errors_active_on" : "errors_active_off"}`}
    >
      {text ?? ""}
    </span>
  );
}
